@import 'bootstrap/scss/_functions';
@import 'bootstrap/scss/_variables';
@import 'bootstrap/scss/_mixins';
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nanum+Gothic&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nanum+Gothic&family=Open+Sans:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nanum+Gothic&family=Open+Sans:wght@300;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nanum+Gothic&family=Open+Sans:wght@300;400;600&display=swap');

// @use "sass:map";

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1440px
);

// #F37335 primary, menuhover, button
// #575757 secondary
// #FFA048 buttonhover
// #2A2A2A text
// #767676 description
// #F2F7FD footerbg
// #155799 metadata

$theme-colors: (
    "primary": #F37335,
    // "primary": #FFA048,
    "secondary": #767676,
    "footer": #F2F7FD,
    "section": #FAFAFB,
    "button": #F37335,
    "button-hover": #FFA048,
    "sidebar": #FAFAFB,
    "link": #F37335,
    "link-hover": #FFA048,
    "title": #2A2A2A,
    "body": #767676,
    "info": #155799,
    "border": #FAFAFB,
    "divider": #FAFAFB,
    "warning": #F37335,
    "inactive": #FAFAFB
);

$colors: (
  "blue": $blue,
  "indigo": $indigo,
  "purple": $purple,
  "pink": $pink,
  "red": $red,
  "orange": $orange,
  "yellow": $yellow,
  "green": $green,
  "teal": $teal,
  "cyan": $cyan,
  "white": $white,
  "gray": $gray-600,
  "gray-dark": $gray-800
);

$btn-font-family: 'Lato';
$btn-font-weight: 'Bold';
// $font-family-base: "Noto Sans KR", "Open Sans", sans-serif;
$font-family-base: "Nanum Gothic", "Open Sans", sans-serif;
// $font-family-base: "Nanum Myeongjo", "Open Sans", sans-serif;

// With variable
.alpha { color: $purple; }

// From the Sass map with our `color()` function
.beta { color: color("purple"); }

// Generate alert modifier classes
@each $color, $value in $theme-colors {
    .alert-#{$color} {
        @include alert-variant(theme-color-level($color, -10), theme-color-level($color, -9), theme-color-level($color, 6));
    }
}

// Generate `.bg-*` color utilities
@each $color, $value in $theme-colors {
    @include bg-variant('.bg-#{$color}', $value);
}

@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

        .text#{$infix}-left   { text-align: left !important; }
        .text#{$infix}-right  { text-align: right !important; }
        .text#{$infix}-center { text-align: center !important; }
    }
}


@import 'bootstrap/scss/bootstrap';

a {
  text-decoration: none;
  color: map-get($theme-colors, "link");
}

a:hover {
    text-decoration: none;
    font-weight: bold;
    color: map-get($theme-colors, "link");
}

.navbar-light .navbar-nav .nav-link {
    color: map-get($theme-colors, "secondary");
    font-weight: 300;
    font-size: 16px;
}

.dropdown-item {
    color: map-get($theme-colors, "secondary");
    font-weight: 300;
    font-size: 14px;
    line-height: 30px;
}

.navbar-light .navbar-nav .nav-link:hover,
.dropdown-item:hover {
    color: map-get($theme-colors, "link");
}

.dropdown-item.active,
.dropdown-item:active {
    color: map-get($theme-colors, "link");
    background-color: #FAFAFB;
}

.navbar-light .navbar-nav .nav-link {
    margin: auto 10px;
}

.navbar-light .navbar-nav .show > .nav-link, 
.navbar-light .navbar-nav .active > .nav-link, 
.navbar-light .navbar-nav .nav-link.show, 
.navbar-light .navbar-nav .nav-link.active {
    color: map-get($theme-colors, "secondary");
    font-weight: 300;
}

.list-group-item.active {
    color: map-get($theme-colors, "secondary");
    background-color: map-get($theme-colors, "sidebar");
    // border-color: map-get($theme-colors, "secondary");
    border: 1px solid rgba(0, 0, 0, 0.125);
    font-size: 14px;
}

.list-group-item {
    font-size: 14px;
}

.primary-title {
    color: map-get($theme-colors, "primary");
}

// .ql-editor, .ql-editor div, .ql-editor li {
//     line-height: 220%;
//   }

.ql-editor {
    line-height: 2.2;
}

.ql-editor .ql-video {
    width: 1024px;
    height: 576px;
}

.gsc-search-button .gsc-search-button-v2 {
    // visibility: hidden;
    font-size: 14px;
    margin: 0;
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    width: auto;
    vertical-align: middle;
    border: 1px solid #666;
    border-radius: 0.25rem;
    border-color: #767676;
    background-color: #767676;
}
// table.gsc-search-box td.gsc-input {
//     width: 130%;
//     padding: 0;
// }
// .gsc-control-cse, .gsc-control-cse .gsc-table-result{
//     width: 130%;
// }

// .gsc-control-cse {
//     padding: 0em;
//   }
  
// .gsc-control-cse,
// .gsc-control-cse .gsc-table-result {
// width: 120%;
// font-family: Arial, sans-serif;
// font-size: 13px;
// }

// .gsc-control-wrapper-cse {
// width: 120%;
// }
  
:root {
    --color-primary: #F37335;
    --color-secondary: #767676;
    --color-body: #2A2A2A;
    --color-button: #F37335;
    --color-background: #FAFAFB;
    --color-line: #E0E1E2;
}